@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');



body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

ul{
  margin:0;
}

body.modal-open {
  overflow: hidden;
}


body {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #999999 #1f1f1f; /* Firefox */
  border-radius: 5px;
}

/* Webkit Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1f1f1f;
}

::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 5px;
}
